import React, { useState, useRef } from 'react';
import '../../superadmin/styles/layouts/common_topbar.css';
import { Typography, Menu, MenuItem, IconButton, Badge, TextField, Button } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import file_icon from '../../admin/assets/icons/profile-icon.svg';
import write_icon from '../../admin/assets/icons/write-icon.svg';
import { useNavigate } from 'react-router-dom';
import user from '../../superadmin/assets/icons/user.png';

const Topbar = () => {
  const navigate = useNavigate();
  //notification menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //profile menu
  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  //file
  const fileInputRef = useRef(null);

  const handleClick2 = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

   //get the token in localstorage
   const name = localStorage.getItem("name");
    
  return (
    <section className='topbar'>
      <div className='topbar-content'>
        <div className='topbar-content-text'>
          <Typography variant='h6'>Hello, Mr. {name}!!</Typography>
          {/* <Typography>Welcome message</Typography> */}
        </div>
        <div className='topbar-notifications'>
          <div>
            {/* <IconButton color="inherit" onClick={handleClick}>
              <Badge badgeContent={4}>
                <NotificationsNoneIcon />
              </Badge>
            </IconButton> */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className='notification'
            >
              <div className='notification-menu'>
                <Typography variant='h5'>Notifications</Typography>
                <Typography>View all</Typography>
              </div>
              <div className='notification-menu-2'>
                <div>
                  <Typography variant='h6'>Ticket ID:</Typography>
                  <span>#11009</span>
                </div>
                <Typography>15 May 2020 5:00 pm</Typography>
              </div>
              <div className='notification-menu-3'>
                <Typography>
                  Lorem ipsum dolor. Sagittis sit venenatis sit nulla id.
                </Typography>
              </div>
            </Menu>
          </div>
          <div className='profiles'>
            <IconButton color="inherit" onClick={handleClick1}>
            <img style={{ width: '30px' }} src={user} />
            </IconButton>
            <Menu
              anchorEl={anchorEl1}
              open={Boolean(anchorEl1)}
              onClose={handleClose1}
              className='profile'
            >
                            <MenuItem onClick={() => { navigate("/admin/profile") }}>Profile</MenuItem>
                            <MenuItem onClick={() => { navigate("/admin/changepassword") }}>Change password</MenuItem>
              {/* <div className='profile-menu'>
                <Typography variant='h5'>profile</Typography>
                <CloseIcon onClick={handleClose1} />
              </div> */}
              {/* <div className='profile-menu-2'>
                <Typography variant='h6'>Name <img src={write_icon} alt='write_icon'/></Typography>
                <TextField type='name' placeholder='Please enter your Name' label="name"  autoComplete='off'/>
              </div> */}
              {/* <div className='profile-menu-3'>
                <div className='select-file' onClick={handleClick2}>
                  <img src={file_icon} alt='' />
                  <Typography variant='h6'>Click to upload <span>or drag and drop</span></Typography>
                  <Typography>
                    Format Jpeg, Png / max size 5Mb
                  </Typography>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(e) => console.log(e.target.files)}
                  />
                </div>
                <div className='file-btn'>
                  <Button  onClick={handleClose1}>Cancel</Button>
                  <Button>Upload</Button>
                </div>
              </div> */}
            </Menu>
            {/* <Typography variant='h4'>Mr.{name} here</Typography> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Topbar
import React, { useState,useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {Dialog,DialogTitle,DialogContent,DialogActions,IconButton,Grid,TextField,InputLabel,FormControl,Select,MenuItem,Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import FileUpload from './FileUpload';
import instance from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as XLSX from 'xlsx';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Add_excelfile({setsendExcelfile}) {
  const [open, setOpen] = React.useState(false);
  const [title, settitle] = useState(""); //usestate for publicnotifications importdata title
  const [discription, setdiscription] = useState(""); //usestate for publicnotifications importdata discription
  const [file, setFile] = useState(null);//usestate for publicnotifications importdata file

  //function open popup
  const handleClickOpen = () => {
    setOpen(true);
  };
   //function close popup
  const handleClose = () => {
    setOpen(false);
    // //clear fields
    settitle("");
    setdiscription("");
    setFile("")
  };


 //sample file
 const openSampleFile = () => {
    // Create an empty XLSX file with a Blob
    const columnNames = ['S.No', 'Number'];
    const allData = [columnNames];
    const worksheet = XLSX.utils.aoa_to_sheet(allData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const content = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Sample Format.xlsx'); // Set the filename to 'sampleFiles.xlsx'
    link.click();
    window.URL.revokeObjectURL(url);
  };

  //import public notification function
  const handleImport = async () => {
    if (!file || !title ||! discription) {
      toast.error("Please provide the fields File,Title and Description")
      return;
    }
    const formData = new FormData();
    formData.append('Numbers', file);
    formData.append('title', title);
    formData.append('discription', discription);

    try {
      const response = await instance.post("Commissioner/sendBulkMessage", formData);
        if (response.data.success === true) {
        toast.success(response.data.message);
        handleClose()
        setsendExcelfile(true)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error importing data:', error.message);
      toast.error('An error occurred while importing data. Please try again.');
    }
  };


  return (
    <div>
      <React.Fragment>
        <Button
          className="title_add_button"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Send Whatsapp
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="add_departments"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Send Public Notificatons
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2} columnSpacing={2} rowSpacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="departments_forms ">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={discription}
                    onChange={(e) => setdiscription(e.target.value)}
                    required
                  />
                </div>
              </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="departments_forms">
                  <TextField
                    id="outlined-basic"
                    type="file" 
                    accept=".xlsx" 
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </Grid>
                <Grid item>
                   <div className="departments_forms public-notifications">
                      <Button className="notification-public-btn-1" onClick={openSampleFile}><ExcelIcon />Download sample</Button>
                    </div>
                </Grid>
            </Grid> 
          </DialogContent>
          <DialogActions className="footer_button">
            <Button
              className="cancel_button"
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="add_button"
              autoFocus
              onClick={handleImport}
            >
              Send
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  )
}

export default Add_excelfile;
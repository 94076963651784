import React, { useState, useEffect } from "react";
import AdminTopbar from '../../admin/layouts/Topbar';
import instance from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Typography, InputAdornment, TextField, TablePagination, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem, IconButton, Menu, FormLabel, RadioGroup, Grid, DialogActions, Dialog, DialogContent, DialogTitle, Tabs, Tab } from "@mui/material";
// import Add_notification from "../components/Add_notifications";
// import Notifications_filter from "../components/Notifications_filter";
import SearchIcon from "@mui/icons-material/Search";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from "@mui/material/styles";
import Add_excelfile from '../../superadmin/components/Add_excelfile';
import Staff_notications from '../../superadmin/components/Staff_notications';





const Notifications = () => {
  //filter
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [open1, setOpen1] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);   //tabs
  const [maagernotification, setmaagernotification] = useState([]);  //usestate for Departments
  const [pagination, setpagination] = useState([]); //pagination
  const [loading, setLoading] = useState(true); // State to handle loading
  const [sortOrder, setSortOrder] = useState('asc');   //array sorting
  const [sortBy, setSortBy] = useState('');   //array sorting
  const [page, setPage] = useState(1); //page usestate
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState('');  // Search usestate
  const startIndex = (page - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const [publicNotifications, setpublicNotifications] = useState([]) //usestate for publicnotifications
  const [paginationpublic, setpaginationpublic] = useState([])
  const [publicpage, setpublicpage] = useState(1)
  const startIndexpublic = (publicpage - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const [sendExcelfile, setsendExcelfile] = useState(false);  //usesate for pass function to addnotification
  const [staffnotifications, setstaffnotifications] = useState([])
  const [paginationstaff, setpaginationstaff] = useState([]);
  const [stafffpage, setsatffpage] = useState(1)
  const startIndexstaff = (stafffpage - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const [sendstaffnotification, setsendstaffnotification] = useState(false); //usesate for addnotification



  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  //tabs function
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  // Calculate the start and end index for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };

  //page function public
  const handleChangePagepublic = (event, newPage) => {
    setpublicpage(newPage + 1); // Adjust to be 1-based
  };
  //page function staff
  const handleChangePagestaff = (event, newPage) => {
    setsatffpage(newPage + 1); // Adjust to be 1-based
  };
  // Filter the field officers based on the search term
  const filteredEmployees = maagernotification?.filter((notification) =>
    notification?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.departmentId?.commissionerId?.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.departmentId?.commissionerId?.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.departmentId?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  //sorting function
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...maagernotification].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setmaagernotification(sortedData);
  };


  //get maagernotification api call
  const fetchmaagernotification = async () => {
    try {
      const response = await instance.get(`Manager/getNotifications?page${page}`);
      setmaagernotification(response.data.data);
      setpagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchmaagernotification();
  }, [page]);


  //get fetchpublicnotifications api call
  const fetchpublicnotifications = async () => {
    try {
      const response = await instance.get(`Commissioner/getPublicNotifications?page=${publicpage}`);
      setpublicNotifications(response.data.publicNotifications);
      setpaginationpublic(response.data.pagination);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchpublicnotifications();
  }, [publicpage]);

  useEffect(() => {
    fetchpublicnotifications();
  }, [sendExcelfile]);

  //sorting function
  const handleSortpublic = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...publicNotifications].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setpublicNotifications(sortedData);
  };


  //get getstaffNotifications api call
  const fetchstaffnotifications = async () => {
    try {
      const response = await instance.get(`Manager/getstaffNotifications?page=${stafffpage}`);
      setstaffnotifications(response.data.employeeNotifications);
      setpaginationstaff(response.data.pagination);
    } catch (error) {
      console.error('Error fetching employeeNotifications:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchstaffnotifications();
  }, [stafffpage]);

  useEffect(() => {
    fetchstaffnotifications();
  }, [sendstaffnotification]);
  //sorting function
  const handleSortsatff = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...staffnotifications].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setstaffnotifications(sortedData);
  };

  return (
    <React.Fragment>
      <AdminTopbar />
      <section className='tabs-section'>
        <React.Fragment>
          <Tabs value={currentTabIndex} onChange={handleTabChange}>
            <Tab label='Admin Notifications' />
            <Tab label='Public Notifications' />
            <Tab label='Staff Notifications' />
          </Tabs>
        </React.Fragment>

        {currentTabIndex === 0 && (
          <React.Fragment>
            {loading ? (
              <div className="loading">
                <CircularProgress />
              </div>
            ) : (
              <section className="employees-1">
                {/* <div className="notifications_title">
                  <h3>
                    Notifications <span>({maagernotification.length})</span>
                  </h3>
                </div> */}
                <div className='employees-text'>
                  <Typography variant='h5'>
                    Notifications Details
                  </Typography>
                  {/* <Button onClick={handleClickOpen}>
                    Add Notifications
                  </Button> */}
                </div>
                <div className="employees-main">
                  <div className='employees-content '>
                    <div className='search-options'>
                      {/* <TextField
                        placeholder='Search'
                        type='search'
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      /> */}
                    </div>
                    <div className='pagination-options'>
                      <div className='pagination-animation'></div>
                      <TablePagination
                        component="div"
                        count={pagination?.totalNotifications}
                        page={page - 1} // Adjust to be 0-based for MUI TablePagination
                        onPageChange={handleChangePage}
                        rowsPerPage={pagination?.limit}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => (
                          <React.Fragment>
                            {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                            <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                  <div className='employee-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className='employee-index'>
                                S.NO
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("title")}>
                                Title
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("description")}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("firstName")}>
                                Commissioner FullName
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("name")}>
                                Department
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("createdAt")}>
                                Created Date
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredEmployees.length > 0 ? (
                            filteredEmployees.map((notification, index) => (
                              <TableRow key={notification._id}>
                                <TableCell>
                                  {startIndex + index + 1}
                                </TableCell>
                                <TableCell>{notification?.title}</TableCell>
                                <TableCell>{notification?.description}</TableCell>
                                <TableCell>{notification?.departmentId?.commissionerId?.firstName} {notification?.departmentId?.commissionerId?.lastName}</TableCell>
                                <TableCell>{notification?.departmentId?.name}</TableCell>
                                <TableCell>
                                  {new Date(notification?.createdAt).toLocaleDateString()} {new Date(notification?.createdAt).toLocaleTimeString()}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
        {currentTabIndex === 1 && (
          <React.Fragment>
            {loading ? (
              <div className="loading">
                <CircularProgress />
              </div>
            ) : (
              <section className="employees">
                <div className='employees-text'>
                  <Typography variant='h5'>
                    Notifications Details
                  </Typography>
                  <Add_excelfile setsendExcelfile={setsendExcelfile} />
                </div>
                <div className="employees-main">
                  <div className='employees-content'>
                    <div className='search-options'>
                      {/* <TextField
                      placeholder='Search'
                      type='search'
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    /> */}
                    </div>
                    <div className='pagination-options'>
                      <div className='pagination-animation'></div>
                      <TablePagination
                        component="div"
                        count={paginationpublic?.totaltickets}
                        page={publicpage - 1} // Adjust to be 0-based for MUI TablePagination
                        onPageChange={handleChangePagestaff}
                        rowsPerPage={pagination?.limit}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => (
                          <React.Fragment>
                            {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                            <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                  <div className='employee-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className='employee-index'>
                                S.NO
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("title")}>
                                Title
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("description")}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("managerName")}>
                                Mobile Number
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name' onClick={() => handleSortpublic("createdAt")}>
                                Created Date
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {publicNotifications.length > 0 ? (
                            publicNotifications.map((publicnotification, index) => (
                              <TableRow key={publicnotification._id}>
                                <TableCell>
                                  {startIndexpublic + index + 1}
                                </TableCell>
                                <TableCell>{publicnotification?.title}</TableCell>
                                <TableCell>{publicnotification?.discription}</TableCell>
                                <TableCell>{publicnotification?.mobileNo}</TableCell>
                                <TableCell>
                                  {new Date(publicnotification?.createdAt).toLocaleDateString()} {new Date(publicnotification?.createdAt).toLocaleTimeString()}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
        {currentTabIndex === 2 && (
          <React.Fragment>
            {loading ? (
              <div className="loading">
                <CircularProgress />
              </div>
            ) : (
              <section className="employees">
                <div className='employees-text'>
                  <Typography variant='h5'>
                    Notifications Details
                  </Typography>
                  <Staff_notications setsendstaffnotification={setsendstaffnotification} />
                </div>
                <div className="employees-main">
                  <div className='employees-content'>
                    <div className='search-options'>
                      {/* <TextField
                      placeholder='Search'
                      type='search'
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                    /> */}
                    </div>
                    <div className='pagination-options'>
                      <div className='pagination-animation'></div>
                      <TablePagination
                        component="div"
                        count={paginationstaff?.totaltickets}
                        page={stafffpage - 1} // Adjust to be 0-based for MUI TablePagination
                        onPageChange={handleChangePagestaff}
                        rowsPerPage={pagination?.limit}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => (
                          <React.Fragment>
                            {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                            <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                  <div className='employee-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className='employee-index'>
                                S.NO
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortsatff("title")}>
                                Title
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortsatff("description")}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortsatff("EmployeeName")}>
                                EmployeeName
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortsatff("EmployeeNumber")}>
                                EmployeeNumber
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name' onClick={() => handleSortsatff("createdAt")}>
                                Created Date
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {staffnotifications.length > 0 ? (
                            staffnotifications.map((staffnotification, index) => (
                              <TableRow key={staffnotification._id}>
                                <TableCell>
                                  {startIndexstaff + index + 1}
                                </TableCell>
                                <TableCell>{staffnotification?.title}</TableCell>
                                <TableCell>{staffnotification?.description}</TableCell>
                                <TableCell>{staffnotification?.EmployeeName}</TableCell>
                                <TableCell>{staffnotification?.EmployeeNumber}</TableCell>
                                <TableCell>
                                  {new Date(staffnotification?.createdAt).toLocaleDateString()} {new Date(staffnotification?.createdAt).toLocaleTimeString()}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};
export default Notifications;

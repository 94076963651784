import React, { useState } from 'react';
import AdminTopbar from '../../admin/layouts/Topbar';
import { Typography } from '@mui/material';
import '../../admin/styles/pages/dashboard.css';
import { Button, Box, IconButton, FormControl, InputLabel, OutlinedInput, InputAdornment, List, ListItem, Grid } from "@mui/material";
import instance from "../../utils/api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const Changepassword = () => {
  const navigate = useNavigate();//navigate
  //handle show password
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword1, setShowPassword1] = useState(false);

  const handleTogglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);

  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };
  //usestate for changepassword
  const [oldPassword, setoldPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [confirmPassword, setconfirmPassword] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("The passwords you've entered don't match. Please make sure both fields contain the same password");
      return;
    }
    try {
      const response = await instance.post('Manager/ChangePassword', {
        oldPassword,
        newPassword,
        confirmPassword,
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        // Clear input fields after a short delay
        setTimeout(() => {
          setoldPassword('');
          setnewPassword('');
          setconfirmPassword('');
        }, 1000); // Adjust the delay as needed
        navigate('/admin/dashboard')
      } else {
        // Handle the case when the response indicates an error
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error changing password:', error.response);
      toast.error(error.response.data.message);
    }
  };



  return (
    <React.Fragment>
      <AdminTopbar />
      <section className='profile'>
        <div className="profile-wrapper">
          <div className='profile-card'>
            <div className='profile-text'>
              <Typography variant='h4'>Change Password</Typography>
            </div>
            <div className='profile-name'>
              <Grid container spacing={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                {/* sx={{dispaly:"flex",justify-content:"center",aligeitems:"center"}} */}
                <Grid item>
                  <FormControl variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={oldPassword}
                      onChange={(e) => setoldPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Old Password"

                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password1">New Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password1"
                      type={showPassword1 ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => setnewPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility1}
                            edge="end"
                          >
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="New Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password2">Verify Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password2"
                      type={showPassword2 ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={(e) => setconfirmPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility2}
                            edge="end"
                          >
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                  </FormControl>
                </Grid>

                <Grid sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                  <Button onClick={handleChangePassword} className='change-btn'>
                    Update Password
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Changepassword
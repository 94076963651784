import React, { useState, useEffect } from "react";
import Topbar from "../layouts/Topbar";
import '../../admin/styles/pages/dashboard.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography,Dialog} from '@mui/material';
import { styled } from "@mui/material/styles";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import dayjs from 'dayjs';
import instance from "../../utils/api";
import CircularProgress from '@mui/material/CircularProgress';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const PublicUsers = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);   //filter
  const open = Boolean(anchorEl);
  const [age, setAge] = useState(""); //filter-dropdown
  const [value, setValue] = useState(dayjs('2022-04-17'));  //date.js
  const [open2, setOpen2] = useState(false); //add department popup
  const [authors, setAuthors] = useState([]);    //usestate for Departments
  const [loading, setLoading] = useState(true); // State to handle loading
  const [sortOrder, setSortOrder] = useState('asc');  //array sorting
  const [sortBy, setSortBy] = useState('');  //array sorting
  const [page, setPage] = useState(1);    //page useste
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState(''); // Search
  const startIndex = (page - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const endIndex = page * rowsPerPage; // Calculate the start and end index for pagination


  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };

  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };


  //get departments api call
  const fetchauthors = async () => {
    try {
      const response = await instance.get("Commissioner/getAuthorsList");
      setAuthors(response.data.authors);
    } catch (error) {
      console.error('Error fetching authors:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchauthors();
  }, []);



  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...authors].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setAuthors(sortedData);
  };

  // // Filter the authors based on the search term
  // const filtereauthors = authors.filter((author) => {
  //   return (
  //     author.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     author?.mobileNo.includes(searchTerm.toLowerCase())
  //   );
  // });
  
  // const paginatedData = filtereauthors.slice(startIndex, endIndex); // Paginate the field departments

 


  return (
    <React.Fragment>
      <Topbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className="employees">
          <div className='employees-text'>
            <Typography variant='h5'>
              Public
            </Typography>
            {/* <React.Fragment>
              <Button
                className="title_add_button"
                variant="outlined"
                onClick={handleClickOpen2}
              >
                Add Departments
              </Button>
            </React.Fragment> */}
          </div>
          <div className="employees-main">
            <div className='employees-content'>
              <div className='search-options'>
                {/* <TextField
                  placeholder='Search'
                  type='search'
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                /> */}
              </div>
              <div className='pagination-options'>
                <div className='pagination-animation'></div>
                <TablePagination
                  component="div"
                  count={authors.length}
                  page={page - 1} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <React.Fragment>
                      {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                      <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className='employee-table'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className='employee-index'>
                          S.NO
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('username')}>
                          User Name
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('mobileNo')}>
                          Mobile Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('createdAt')}>
                            Created Date
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {authors.length > 0 ? (
                      authors.map((author, index) => (
                        <TableRow tabIndex={-1} key={author._id}>
                          <TableCell>{startIndex + index + 1}</TableCell>
                          <TableCell>{author?.username}</TableCell>
                          <TableCell>{author?.countrycode} {author?.mobileNo}</TableCell>
                          <TableCell>{new Date(author?.createdAt).toLocaleDateString()} {new Date(author?.createdAt).toLocaleTimeString()}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default PublicUsers;

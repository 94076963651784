import React, { useState, useEffect } from 'react';
import AdminTopbar from '../../admin/layouts/Topbar';
import '../../superadmin/styles/pages/dashboard.css';
import instance from "../../utils/api";
import { List, ListItem, TextField, Typography } from '@mui/material';
import user from '../../superadmin/assets/icons/user.png';
import CircularProgress from '@mui/material/CircularProgress';

const Profile = () => {
  //usestate for profile
  const [profile, setprofile] = useState([]);
  // State to handle loading
  const [loading, setLoading] = useState(true); 

  //get profile api call
  const fetchprofile = async () => {
    try {
      const response = await instance.get("Manager/getProfile");
      setprofile(response.data.manager);
    } catch (error) {
      console.error('Error fetching profile:', error.message);
    }finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchprofile();
  }, []);

  return (
    <React.Fragment>
     <AdminTopbar />
     {loading ? (
          <div className="loading">
            <CircularProgress />
          </div>
      ) : (
      <section className='profile'>
        <div className="profile-wrapper">
          <div className='profile-card'>
            <div className='profile-image'>
              <img src={user} alt='' />
            </div>
            <div className='profile-name'>
              <List>
                <ListItem>
                  <Typography variant='h5'>Full Name</Typography>
                  <Typography>{profile?.firstName} {profile?.lastName} </Typography>
                </ListItem>
                <ListItem>
                  <Typography variant='h5'>Email</Typography>
                  <Typography>{profile?.email}</Typography>
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </section>
      )
      }
    </React.Fragment>
  )
}

export default Profile
import React, { useState, useEffect } from 'react';
import instance from '../../utils/api';
import { Navigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, FormControl, TextField, InputAdornment, TablePagination, FormLabel, RadioGroup, FormControlLabel, Radio, IconButton, MenuItem, Select, Menu, InputLabel, Tooltip } from '@mui/material';
import AdminTopbar from '../../admin/layouts/Topbar';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';



const EmployeeCheckinDetails = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);  //filter
    const open = Boolean(anchorEl);
    const startOfMonth = dayjs().startOf('month');  // Get the first day of the current month
    const endOfMonth = dayjs().endOf('month');  // Get the last day of the current month
    const [startDate, setStartDate] = useState(startOfMonth); //usestate for startdate
    const [endDate, setEndDate] = useState(endOfMonth); //usestate for endDate
    const { empId } = useParams();  //usestate for employeeId
    const [employeeDetails, setEmployeeDetails] = useState([]); //usestate for employee
    const [sortOrder, setSortOrder] = useState('asc'); //array sorting
    const [sortBy, setSortBy] = useState(''); //array sorting
    const [searchTerm, setSearchTerm] = useState('');   // Search
    const [page, setPage] = useState(1);  //page setup
    const rowsPerPage = 10; // Fixed rows per page
    const startIndex = (page - 1) * rowsPerPage;      // Calculate the start and end index for pagination
    const endIndex = page * rowsPerPage;      // Calculate the start and end index for pagination
    const filtertooltip = "Filter Menu"; // Define the tooltip text

    //menu fucntion open 
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    //menu fucntion close
    const handleClose1 = () => {
        setAnchorEl(null);
    };

    // Utility function to add leading zeros
    const formatNumber = (num, size) => {
        let s = String(num);
        while (s.length < size) s = "0" + s;
        return s;
    };

    //sorting
    // const handleSort = (field) => {
    //     const isAsc = sortBy === field && sortOrder === 'asc';
    //     setSortOrder(isAsc ? 'desc' : 'asc');
    //     setSortBy(field);

    //     const sortedData = [...employeeDetails].sort((a, b) => {
    //         if (a[field] < b[field]) return isAsc ? -1 : 1;
    //         if (a[field] > b[field]) return isAsc ? 1 : -1;
    //         return 0;
    //     });

    //     setEmployeeDetails(sortedData);
    // };

    //page set function
    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1); // Adjust to be 1-based
    };


    //fetch getEmployeeCheckinouts
    const getEmployeeCheckinouts = async () => {
        try {
            const response = await instance.post(`Manager/getEmployeeCheckinouts/${empId}?startDate=${startDate.format('YYYY/MM/DD')}&endDate=${endDate.format('YYYY/MM/DD')}`);
            setEmployeeDetails(response.data.data);
        } catch (error) {
            console.error('Error fetching employee details:', error.message);
        }
    };

    useEffect(() => {
        getEmployeeCheckinouts();
    }, [empId]);

  // Get the current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // Note: Months are 0-indexed in JavaScript (0 = January, 11 = December)

  // Filter data for the current month
  const currentMonthDetails = employeeDetails.filter(detail => {
      const detailDate = new Date(detail.date);
      return detailDate.getFullYear() === currentYear && detailDate.getMonth() === currentMonth;
  });

    //submit button
    const handleSubmit = () => {
        getEmployeeCheckinouts(); // Fetch data based on the selected date range
        handleClose1()
        setPage(1)
    };

  



    // Filter the currentMonthDetails based on the search term
    // const filteredDetails = currentMonthDetails.filter((detail) => {
    //     const searchTermLower = searchTerm.toLowerCase();

    //     return (
    //         detail?.status?.toLowerCase().includes(searchTermLower)
    //     );
    // });

    // Paginate the months data
    const paginatedData = currentMonthDetails.slice(startIndex, endIndex);

    //resetEmployeeCheckinouts 
    // const resetEmployeeCheckinouts = async () => {
    //     try {
    //         const startOfMonth = dayjs().startOf('month');
    //         // Get the end of the current month
    //         const endOfMonth = dayjs().endOf('month');
    //         const response = await instance.post(`Manager/getEmployeeCheckinouts/${empId}?startDate=${startOfMonth}&endDate=${endOfMonth}`);
    //         setEmployeeDetails(response.data.data);
    //     } catch (error) {
    //         console.error('Error fetching employee details:', error.message);
    //     }
    // };
    
    // const resetToCurrentMonth = () => {
    //     // Fetch data for the current month
    //     resetEmployeeCheckinouts();
    //     handleClose1()
    // };
    return (
        <React.Fragment>
            <AdminTopbar />
            <section className='employees'>
                <div className='employees-text'>
                    <Typography variant='h5'>Staff Checkin Details</Typography>
                    <Button className='employee-btn' onClick={() => navigate("/admin/staff")}><KeyboardBackspaceIcon />Back</Button>
                </div>
                <div className='employees-main'>
                    <div className='employees-content '>
                        <div className='search-options'>
                            {/* <TextField
                                placeholder='Search'
                                type='search'
                                variant="outlined"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            /> */}
                        </div>
                        <div className='pagination-options'>
                            <div className='pagination-animation'></div>
                            <TablePagination
                                component="div"
                                count={employeeDetails.length}
                                page={page - 1} // Adjust to be 0-based for MUI TablePagination
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={({ from, to, count }) => (
                                    <React.Fragment>
                                        {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                                        <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                                    </React.Fragment>
                                )}
                            />
                            <div className='filter'>
                                <IconButton
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick1}
                                >
                                     <Tooltip title={filtertooltip}><FilterAltIcon /></Tooltip>
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    className='filter-options'
                                >
                                    <div className='filter-menu'>
                                        <Typography variant='h6'>Filter Check-Ins and Check-Outs</Typography>
                                        <CloseIcon onClick={handleClose1} />
                                    </div>
                                    <div className='filter-status'>
                                    <div className='employee-checkin-date'>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newValue) => setStartDate(newValue)}
                                                format="DD/MM/YYYY"
                                            />
                                            <DatePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newValue) => setEndDate(newValue)}
                                                format="DD/MM/YYYY"
                                            />

                                        </LocalizationProvider>
                                    </div>
                                    </div>
                                    <div className='apply_fliters filter-btn'>
                                    {/* <Button className='reset-filter' onClick={() => {
                                            setStartDate(startOfMonth);
                                            setEndDate(endOfMonth);
                                            resetToCurrentMonth();
                                        }}>Reset Fliter</Button> */}
                                    <Button onClick={handleSubmit}>Apply Fliter</Button>
                                    </div>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='employee-table'>
                        <TableContainer component={Paper}>
                            <Table aria-label="check-in check-out table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='employee-name department-name'>Date</TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name' >
                                                Check-In Time
                                                {/* onClick={() => handleSort('checkInTime')} 
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name'>
                                                Check-Out Time
                                                {/*onClick={() => handleSort('checkOutTime')} 
                                                 <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name' >
                                                Check-Out Latitude
                                                {/* onClick={() => handleSort('checkout_latitude')}
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name'>
                                                Check-Out Longitude
                                                {/* onClick={() => handleSort('checkout_longitude')}
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name'>
                                                Check-In Latitude
                                                {/* onClick={() => handleSort('latitude')} 
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name'>
                                                Check-In Longitude
                                                {/* onClick={() => handleSort('longitude')} 
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className='employee-name department-name'>
                                                Status
                                                {/* onClick={() => handleSort('status')} 
                                                <NorthIcon />
                                                <SouthIcon /> */}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.length > 0 ? (
                                        paginatedData.map((detail, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{new Date(detail.date).toLocaleDateString()}</TableCell>
                                                <TableCell>
                                                    {detail?.checkInTime}
                                                </TableCell>
                                                <TableCell>
                                                    {detail?.checkOutTime}
                                                </TableCell>
                                                <TableCell>
                                                    {detail?.checkout_latitude}
                                                </TableCell>
                                                <TableCell>
                                                    {detail?.checkout_longitude}
                                                </TableCell>
                                                <TableCell>
                                                    {detail?.latitude}
                                                </TableCell>
                                                <TableCell>
                                                    {detail?.longitude}
                                                </TableCell>
                                                <TableCell> <span style={{
                                                    background: detail.status === "absent" ? "#ff0000" : detail.status === "present" ? "rgb(95 151 95)" : "inherit",
                                                    color: detail.status === "absent" ? "#ffffff" : detail.status === "present" ? "#ffffff" : "inherit",
                                                }}>
                                                    {detail?.status}
                                                </span></TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={12} align="center">
                                                <Typography> No check-in/check-out details available for month.</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default EmployeeCheckinDetails
import React, { useState, useEffect } from "react";
import Topbar from "../../superadmin/layouts/Topbar";
import instance from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Typography, InputAdornment, TextField, TablePagination, FormControlLabel, Radio, FormControl, InputLabel, Select, MenuItem, IconButton, Menu, FormLabel, RadioGroup, Tabs, Tab, Grid } from "@mui/material";
import Add_notification from "../../superadmin/components/Add_notifications";
// import Notifications_filter from "../components/Notifications_filter";
import SearchIcon from "@mui/icons-material/Search";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';
import ExcelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as XLSX from 'xlsx';
import Add_excelfile from '../../superadmin/components/Add_excelfile'


const Notifications = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);   //filter
  const open = Boolean(anchorEl);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);   //tabs
  const [sendnotification, setsendnotification] = useState(false);  //usesate for pass function to addnotification
  const [sendExcelfile, setsendExcelfile] = useState(false);  //usesate for pass function to addnotification
  const [maagernotification, setmaagernotification] = useState([]);    //usestate for maagernotification
  const [pagination, setpagination] = useState([]);    //pagination
  const [loading, setLoading] = useState(true); // State to handle loading
  const [sortOrder, setSortOrder] = useState('asc'); //array sorting
  const [sortBy, setSortBy] = useState('');//array sorting
  const [page, setPage] = useState(1);//page usestate
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState('');    // Search
  const [publicNotifications, setpublicNotifications] = useState([]) //usestate for publicnotifications
  const startIndex = (page - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const [paginationpublic, setpaginationpublic] = useState([])
  const [publicpage, setpublicpage] = useState(1)
  const startIndexpublic = (publicpage - 1) * rowsPerPage; // Calculate the start and end index for pagination

  //menu function open
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //menu function close
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };
  //tab function
  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  //page function commissioner
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };

  //page function public
  const handleChangePagepublic = (event, newPage) => {
    setpublicpage(newPage + 1); // Adjust to be 1-based
  };
  //get maagernotification api call
  const fetchmaagernotification = async () => {
    try {
      const response = await instance.get(`Commissioner/managerNotifications?page=${page}`);
      setmaagernotification(response.data.data);
      setpagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching managernotification:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchmaagernotification();
  }, [page]);

  useEffect(() => {
    fetchmaagernotification();
  }, [sendnotification]);

  //get fetchpublicnotifications api call
  const fetchpublicnotifications = async () => {
    try {
      const response = await instance.get(`Commissioner/getPublicNotifications?page=${publicpage}`);
      setpublicNotifications(response.data.publicNotifications);
      setpaginationpublic(response.data.pagination);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchpublicnotifications();
  }, [publicpage]);

  useEffect(() => {
    fetchpublicnotifications();
  }, [sendExcelfile]);

  //sorting function
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...maagernotification].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setmaagernotification(sortedData);
  };


  // Filter the field officers based on the search term
  const filteredEmployees = maagernotification?.filter((notification) =>
    notification?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.Description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.managerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    notification?.managerNumber?.toString().toLowerCase().includes(searchTerm)
  );

  //sorting function
  const handleSortpublic = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...publicNotifications].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setpublicNotifications(sortedData);
  };




  return (
    <React.Fragment>
      <Topbar />

      <section className='tabs-section'>
        <React.Fragment>
          <Tabs value={currentTabIndex} onChange={handleTabChange}>
            <Tab label='Staff Notifications' />
            <Tab label='Public Notifications' />
          </Tabs>
        </React.Fragment>
        {currentTabIndex === 0 && (
          <React.Fragment>
            {loading ? (
              <div className="loading">
                <CircularProgress />
              </div>
            ) : (
              <section className="employees">
                {/* <div className="notifications_title">
          <h3>
            Notifications <span>({maagernotification.length})</span>
          </h3>
        </div> */}
                <div className='employees-text'>
                  <Typography variant='h5'>
                    Notifications Details
                  </Typography>
                  <React.Fragment>
                    <Add_notification setsendnotification={setsendnotification} />
                  </React.Fragment>
                </div>
                <div className="employees-main">
                  <div className='employees-content'>
                    <div className='search-options'>
                      {/* <TextField
                        placeholder='Search'
                        type='search'
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      /> */}
                    </div>
                    <div className='pagination-options'>
                      <div className='pagination-animation'></div>
                      <TablePagination
                        component="div"
                        count={pagination?.totalNotifications}
                        page={page - 1} // Adjust to be 0-based for MUI TablePagination
                        onPageChange={handleChangePage}
                        rowsPerPage={pagination?.limit}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => (
                          <React.Fragment>
                            {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                            <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                          </React.Fragment>
                        )}
                      />
                      {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter Departments</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                  <div className='filter-status'>
                    <React.Fragment>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="Active" control={<Radio />} label="Active" className='active' />
                          <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" className='inactive' />
                        </RadioGroup>
                      </FormControl>
                    </React.Fragment>
                    <div className="filter_dropdown">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Departments"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter_date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            label="Start"
                            defaultValue={dayjs("2022-04-17")}
                          />
                          <DatePicker
                            label="End"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='apply_fliters'>
                    <Button>Apply Fliter</Button>
                  </div>
                </Menu>
              </div>  */}
                    </div>
                  </div>
                  <div className='employee-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className='employee-index'>
                                S.No
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("title")}>
                                Title
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("description")}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("managerName")}>
                                Department Head Name
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSort("managerNumber")}>
                                Mobile Number
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name' onClick={() => handleSort("createdAt")}>
                                Created Date
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredEmployees.length > 0 ? (
                            filteredEmployees.map((notification, index) => (
                              <TableRow key={notification._id}>
                                <TableCell>
                                  {startIndex + index + 1}
                                </TableCell>
                                <TableCell>{notification?.title}</TableCell>
                                <TableCell>{notification?.description}</TableCell>
                                <TableCell>{notification?.managerName}</TableCell>
                                <TableCell>{notification?.managerNumber}</TableCell>
                                <TableCell>
                                  {new Date(notification?.createdAt).toLocaleDateString()} {new Date(notification?.createdAt).toLocaleTimeString()}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}

        {currentTabIndex === 1 && (
          <React.Fragment>
            {loading ? (
              <div className="loading">
                <CircularProgress />
              </div>
            ) : (
              <section className="employees">
                <div className='employees-text'>
                  <Typography variant='h5'>
                    Notifications Details
                  </Typography>
                  <Add_excelfile setsendExcelfile={setsendExcelfile} />
                </div>
                <div className="employees-main">
                  <div className='employees-content'>
                    <div className='search-options'>
                      {/* <TextField
                        placeholder='Search'
                        type='search'
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <SearchIcon />
                            </InputAdornment>
                          )
                        }}
                      /> */}
                    </div>
                    <div className='pagination-options'>
                      <div className='pagination-animation'></div>
                      <TablePagination
                        component="div"
                        count={paginationpublic?.totaltickets}
                        page={publicpage - 1} // Adjust to be 0-based for MUI TablePagination
                        onPageChange={handleChangePagepublic}
                        rowsPerPage={pagination?.limit}
                        rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to, count }) => (
                          <React.Fragment>
                            {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                            <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                          </React.Fragment>
                        )}
                      />
                    </div>
                  </div>
                  <div className='employee-table'>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div className='employee-index'>
                                S.NO
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("title")}>
                                Title
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("discription")}>
                                Description
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name department-name' onClick={() => handleSortpublic("mobileNo")}>
                                Mobile Number
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className='employee-name' onClick={() => handleSortpublic("createdAt")}>
                                Created Date
                                <NorthIcon />
                                <SouthIcon />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {publicNotifications.length > 0 ? (
                            publicNotifications.map((publicnotification, index) => (
                              <TableRow key={publicnotification._id}>
                                <TableCell>
                                  {startIndexpublic + index + 1}
                                </TableCell>
                                <TableCell>{publicnotification?.title}</TableCell>
                                <TableCell>{publicnotification?.discription}</TableCell>
                                <TableCell>{publicnotification?.mobileNo}</TableCell>
                                <TableCell>
                                  {new Date(publicnotification?.createdAt).toLocaleDateString()} {new Date(publicnotification?.createdAt).toLocaleTimeString()}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                <Typography>No Data Found</Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </section>
            )}
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};
export default Notifications;

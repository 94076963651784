import React, { useState, useEffect } from "react";
import Topbar from "../layouts/Topbar";
import '../../admin/styles/pages/dashboard.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, TextField,
  InputAdornment, Button, MenuItem, Menu, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, InputLabel, Select, Grid, DialogActions,
  DialogContent, DialogTitle, Dialog,
  Tooltip
} from '@mui/material';
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import instance from "../../utils/api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import edit from "../../superadmin/assets/icons/table/edit.svg";
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


const Departments = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);   //filter
  const open = Boolean(anchorEl);
  const [age, setAge] = useState(""); //filter-dropdown
  const [value, setValue] = useState(dayjs('2022-04-17'));  //date.js
  const [open2, setOpen2] = useState(false); //add department popup
  const [departments, setDepartments] = useState([]);    //usestate for Departments
  const [loading, setLoading] = useState(true); // State to handle loading
  const [sortOrder, setSortOrder] = useState('asc');  //array sorting
  const [sortBy, setSortBy] = useState('');  //array sorting
  const [page, setPage] = useState(1);    //page useste
  const rowsPerPage = 10; // Fixed rows per page
  const [searchTerm, setSearchTerm] = useState(''); // Search
  const [name, setname] = useState("");     //usestate for name
  const [Description, setDescription] = useState(""); //usestate for Description
  const [status, setstatus] = useState("");//usestate for status
  const [departmentId, setdepartmentId] = useState(""); //usestate for departmentId
  const startIndex = (page - 1) * rowsPerPage; // Calculate the start and end index for pagination
  const endIndex = page * rowsPerPage; // Calculate the start and end index for pagination
  const [editingdepartment, setEditingdepartment] = useState(null); //update departments
  const topoltipstatus = "Update status description and department";
  const tooltipadddepartments = "Create Department Dialog"

  //page function
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };

  //filter menu open
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
 //filter menu close
  const handleClose1 = () => {
    setAnchorEl(null);
  };

 //add and update departements popup open
  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  //add and update departements popup close
  const handleClose2 = () => {
    setOpen2(false);
    setname("");
    setDescription("");
    setEditingdepartment(null)
  };

  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  //get departments api call
  const fetchdepartments = async () => {
    try {
      const response = await instance.get("Commissioner/getDepartments");
      setDepartments(response.data.departments);
    } catch (error) {
      console.error('Error fetching departments:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdepartments();
  }, []);


  //add departments
  const adddepartments = async () => {
    try {
      //validation
      if (!name || !Description) {
        toast.error('Please Enter Department Name and Description.');
        return;
      }
      const response = await instance.post("Commissioner/Add_Department",
        {
          name,
          Description
        });

      if (response.data.success === true) {
        toast.success(response.data.message);
        fetchdepartments();
        handleClose2();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error.response);
    }
  }

  //sorting function
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...departments].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setDepartments(sortedData);
  };


  // // Filter the departments based on the search term
  // const filteredepartments = departments.filter((department) => {
  //   const departmentHeadFullName = `${department?.DepartmentHead?.firstName.toLowerCase()} ${department?.DepartmentHead?.lastName.toLowerCase()}`;
  //   return (
  //     department?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || department?.name?.toUpperCase().includes(searchTerm.toUpperCase())||
  //     department?.Description.includes(searchTerm.toLowerCase())||
  //     departmentHeadFullName.includes(searchTerm.toLowerCase())
  //   );
  // });
  
  // const paginatedData = filteredepartments.slice(startIndex, endIndex); // Paginate the field departments

  //update departments function
  const handleEditClick = (department) => {
    setEditingdepartment(department);
    // Pre-fill the input fields with the existing data
    setname(department?.name || "");
    setDescription(department?.Description || "");
    setstatus(department?.status === true ? "true" : "false");
    setdepartmentId(department?._id);
    //modal open
    setOpen2(true);
  };

//update departments function  api
  const updatdepartment = async () => {
    try {
      //validation
      if (!name || !Description || !status) {
        toast.error('Please enter department name status and description.');
        return;
      }
      const response = await instance.post(`Commissioner/update_Department`, {
        name,
        Description,
        status,
        departmentId

      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        fetchdepartments();
        handleClose2();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.response);
      toast.error(error)
    }
  }


  return (
    <React.Fragment>
      <Topbar />
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose2}
          aria-labelledby="customized-dialog-title"
          open={open2}
          className="add_departments"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingdepartment ? "Update Department" : "Add Department"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose2}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Department Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
              </Grid>
              {editingdepartment ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="filter_dropdown">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label1">status</InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          id="demo-simple-select"
                          label="status"
                          value={status}
                          onChange={(e) => setstatus(e.target.value)}
                        >
                          <MenuItem value="true">Active</MenuItem>
                          <MenuItem value="false">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  {editingdepartment ? null :
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="filter_dropdown">
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Department</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Department"
                              value={departmentId}
                              onChange={(e) => setdepartmentId(e.target.value)}
                              disabled={editingdepartment}
                            >
                              {departments.map((department) => (
                                <MenuItem key={department?._id} value={department?._id}>{department.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Grid>
                    </>
                  }
                </>
              ) : null}
            </Grid>
          </DialogContent>
          <DialogActions className="footer_button">
            <Button
              className="cancel_button"
              autoFocus
              onClick={handleClose2}
            >
              Cancel
            </Button>
            <Button
              className="add_button"
              onClick={editingdepartment ? updatdepartment : adddepartments}
            >
              {editingdepartment ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className="employees">
          <div className='employees-text'>
            <Typography variant='h5'>
              Departments
            </Typography>
            <React.Fragment>
              <Button
                className="title_add_button"
                variant="outlined"
                onClick={handleClickOpen2}>
                Add Department
              </Button>
            </React.Fragment>
          </div>
          <div className="employees-main">
            <div className='employees-content'>
              <div className='search-options'>
                {/* <TextField
                  placeholder='Search'
                  type='search'
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                /> */}
              </div>
              <div className='pagination-options'>
                <div className='pagination-animation'></div>
                <TablePagination
                  component="div"
                  count={departments.length}
                  page={page - 1} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <React.Fragment>
                      {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                      <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                    </React.Fragment>
                  )}
                />
                {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter Departments</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                  <div className='filter-status'>
                    <React.Fragment>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="Active" control={<Radio />} label="Active" className='active' />
                          <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" className='inactive' />
                        </RadioGroup>
                      </FormControl>
                    </React.Fragment>
                    <div className="filter_dropdown">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Departments"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter_date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            label="Start"
                            defaultValue={dayjs("2022-04-17")}
                          />
                          <DatePicker
                            label="End"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='apply_fliters'>
                    <Button>Apply Fliter</Button>
                  </div>
                </Menu>
              </div> */}
              </div>
            </div>
            <div className='employee-table'>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className='employee-index'>
                          S.NO
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('name')}>
                          Department
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('name')}>
                          DepartmentHead Name
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('Description')}>
                          Description
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name department-name' onClick={() => handleSort('status')}>
                          status
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-index'>
                          Action
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {departments.length > 0 ? (
                      departments.map((department, index) => (
                        <TableRow  key={department._id}>
                          <TableCell>{startIndex + index + 1}</TableCell>
                          <TableCell>{department?.name}</TableCell>
                          <TableCell>{department?.DepartmentHead?.firstName} {department?.DepartmentHead?.lastName}</TableCell>
                          <TableCell>{department?.Description}</TableCell>
                          <TableCell>
                            <span style={{ color: department.status === true ? "#00A651" : " #EB5757", background: department.status === true ? " #E5FCF0" : "#FFE8E8" }}>
                              {department?.status === true ? "Active" : "Inactive"}
                            </span>
                          </TableCell>
                          <TableCell><Tooltip title={topoltipstatus}><div onClick={() => handleEditClick(department)} style={{ cursor: "pointer" }}><img src={edit} alt="" /></div></Tooltip></TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default Departments;

import React, { useState,useEffect } from 'react';
import { styled } from "@mui/material/styles";
import {Dialog,DialogTitle,DialogContent,DialogActions,IconButton,Grid,TextField,InputLabel,FormControl,Select,MenuItem,Button} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import FileUpload from './FileUpload';
import instance from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Add_notification({setsendnotification}) {
  const [open, setOpen] = React.useState(false);
  const [departments, setDepartments] = useState([]);     //usestate for Departments

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    //clear fields
    settitle("");
    setdiscription("");
    setdepid("")
    
  };

  //get departments api call
  const fetchdepartments = async () => {
    try {
      const response = await instance.get("Commissioner/getDepartments");
      setDepartments(response.data.departments);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  useEffect(() => {
    fetchdepartments();
  }, []);

  //usestate for sendNotification
  const [title, settitle] = useState("");
  const [discription, setdiscription] = useState("");
  const [depid, setdepid] = useState("");

  //sendNotification
  const sendNotification = async () => {
    try {
    // Validation
if (!title || !discription || !depid) {
  toast.error('Please enter title, department ID, and discription.');
  return;
}
      const response = await instance.post("sendNotification",
        {
          title,
          discription,
          depid
        });

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleClose()
        setsendnotification(true)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.message);
      console.error(error.response);
    }
  }

  return (
    <div>
      <React.Fragment>
        <Button
          className="title_add_button"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Send Whatsapp
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="add_departments"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
           Send Commissioner Notifications
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2} columnSpacing={2} rowSpacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="departments_forms">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={discription}
                    onChange={(e) => setdiscription(e.target.value)}
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="filter_dropdown">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Department</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Department"
                      value={depid}
                      onChange={(e) => setdepid(e.target.value)}
                      required
                    >

                      {departments.map((department) => (
                        <MenuItem key={department?._id} value={department?._id}>{department.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            {/* <div className=''>
              <FileUpload />
            </div> */}
          </DialogContent>
          <DialogActions className="footer_button">
            <Button
              className="cancel_button"
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="add_button"
              autoFocus
              onClick={sendNotification}
            >
              Send
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  )
}

export default Add_notification;
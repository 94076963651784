import React, { useState, useEffect } from 'react';
import '../../superadmin/styles/layouts/common_sidebar.css';
import { Link, Outlet } from "react-router-dom";
import { Button, Grid, List, ListItem } from '@mui/material';
import { useLocation } from "react-router-dom";
import logo from '../../superadmin/assets/logo.svg';
import dashboard_icon from '../../superadmin/assets/icons/dashboard-icon.svg';
import departments_icon from '../../superadmin/assets/icons/deparment-icon.svg';
import employees_icon from '../../superadmin/assets/icons/employee-icon.svg';
import managers_icon from '../../superadmin/assets/icons/managers.svg';
import tickets_icon from '../../superadmin/assets/icons/tickets.svg';
import notification_icon from '../../superadmin/assets/icons/notifications.svg';
import logout_icon from '../../superadmin/assets/icons/logout-icon.svg';
import { useNavigate } from "react-router-dom";
import instance from "../../utils/api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import public_icon from '../../superadmin/assets/icons/public-user.png'
// import notificationSound from '../../superadmin/assets/icons/new-notification-female-voice.mp3';

const Sidebar = () => {
  const navigate = useNavigate();
  //active state
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('status');
    localStorage.removeItem('role');
    localStorage.removeItem("name")
    // Redirect to the login page
    navigate('/superadmin');
    window.location.reload()
  };

  //usestate for Notifications
  // const [notifications, setNotifications] = useState([]);
  // setNotifications(response.data.tickets);

  //get Notifications api call
  const fetchNotifications = async () => {
    try {
      const response = await instance.get("Commissioner/getNotifications");
      const notificationstaff = response.data.tickets;
      const notificationpublic = response.data.PublicTickets;
      if (notificationstaff.length !== 0 || notificationpublic.length !== 0) {
        showNotificationToast({ notificationstaff, notificationpublic })
      }
    } catch (error) {
      console.error('Error fetching Notifications:', error.message);
    }
  };


  // const audio = new Audio();
  const showNotificationToast = ({ notificationstaff, notificationpublic }) => {
    const stafflength = notificationstaff.length;
    const publiclength = notificationpublic.length;
    toast.info(
      <div>
        {stafflength > 0 ? (<strong>{stafflength} {stafflength === 1 ? 'Staff ticket' : 'Staff tickets'} have been raised</strong>) : null}<br />
        {publiclength > 0 ? (<strong>{publiclength} {publiclength === 1 ? 'Public ticket' : 'Public tickets'} have been raised</strong>) : null}
      </div>,
      {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        onClick: () => {

          navigate(`/superadmin/tickets`);
        },
      }
    );

    // audio.src = notificationSound;
    // audio.play();
  };


  useEffect(() => {
    // Call the function initially
    fetchNotifications();

    // Set up the interval to call the function every 5 minutes (300000 ms)
    const intervalId = setInterval(async () => {
      await fetchNotifications();
    }, 120000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);



  return (
    <React.Fragment>
      <Grid container >
        <Grid className='side_left_grid' item lg={2} >
          <div className='sidebar-content'>
            {/* <img className='sidebar-logo' src={logo} alt='logo' />// */}
            <a href='/superadmin/dashboard'>
              <h1 className='header'>Zilla Parishad</h1>
            </a>
            <List>
              <Link to="/superadmin/dashboard">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "dashboard" ? "active" : ""}>
                  <img src={dashboard_icon} alt='dashboard icon' />
                  <span>Dashboard</span>
                </ListItem>
              </Link>
              <Link to="/superadmin/departments">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "departments" ? "active" : ""}>
                  <img src={departments_icon} alt='departments icon' />
                  <span>Departments</span>
                </ListItem>
              </Link>
              <Link to="/superadmin/managers">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "managers" ? "active" : ""}>
                  <img src={managers_icon} alt='managers_icon' />
                  <span>Department Head</span>
                  {/* <span>Managers</span> */}
                </ListItem>
              </Link>
              <Link to="/superadmin/Staff">
                <ListItem className={(splitLocation[1] === "superadmin" && splitLocation[2] === "Staff") || (splitLocation[1] === "superadmin" && splitLocation[2] === "employee-details") ? "active" : ""}>
                  <img src={employees_icon} alt='employees icon' />
                  <span>Staff</span>
                </ListItem>
              </Link>
              <Link to="/superadmin/public-users">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "public-users" ? "active" : ""}>
                  <img src={public_icon} alt='public icon' />
                  <span>Public</span>
                </ListItem>
              </Link>
              <Link to="/superadmin/tickets">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "tickets" ? "active" : ""}>
                  <img src={tickets_icon} alt='tickets icon' />
                  <span>Tickets</span>
                </ListItem>
              </Link>
              <Link to="/superadmin/notifications">
                <ListItem className={splitLocation[1] === "superadmin" && splitLocation[2] === "notifications" ? "active" : ""}>
                  <img src={notification_icon} alt='notification icon' />
                  <span>Notifications</span>
                </ListItem>
              </Link>
            </List>
            <div className='sidebar-border'></div>
            <div className='logout'>
              <Button onClick={handleLogout}><img src={logout_icon} alt='notification icon' />LOGOUT</Button >
            </div>
          </div>
        </Grid>
        <Grid className='side_right_grid' item lg={10}>
          <div className='right_side_bar'>
            <Outlet />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Sidebar
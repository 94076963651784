import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, TextField,
  InputAdornment, Button, MenuItem, Menu, IconButton, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, InputLabel, Select,
  Dialog, DialogContent, DialogTitle, Grid, DialogActions, FormHelperText,
  Tooltip
} from '@mui/material';
import instance from '../../utils/api';
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AdminTopbar from '../../admin/layouts/Topbar';
import '../../admin/styles/pages/dashboard.css';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import edit from "../../superadmin/assets/icons/table/edit.svg";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));



const Employees = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);   //filter
  const open = Boolean(anchorEl);
  const [age, setAge] = useState("");  //filter-dropdown
  const [value, setValue] = React.useState(dayjs("2022-04-17"));  //date-picker
  const [employees, setEmployees] = useState([]);  //usesate for employee
  const [pagination, setpagination] = useState([]);  //usesate for pagination
  const [page, setPage] = useState(1); //page usestate
  const rowsPerPage = 10; // Fixed rows per page
  const [loading, setLoading] = useState(true);  // State to handle loading
  const [input, setinput] = useState("")   //usestate for input filter
  const [sortOrder, setSortOrder] = useState('asc'); // sorting usestate
  const [sortBy, setSortBy] = useState(''); //usestate for sort
  const [openDialog, setOpenDialog] = useState(false); //usestate for open popup
  const [selectedEmployee, setSelectedEmployee] = useState(null); //usestate for selectemployee
  const [firstNameError, setfirstNameError] = useState("");  //usesate validation  firstNameError
  const [lastNameError, setlastNameError] = useState("");//usesate validation  lastNameError
  const [mobileNoError, setmobileNoError] = useState("")//usesate validation  mobileNoError
  const [emailError, setemailError] = useState("");//usesate validation  emailError
  const [adharNumberError, setadharNumberError] = useState(""); //usesate validation  adharNumberError
  const [countrycodeError, setcountrycodeError] = useState("");//usesate validation  countrycodeError
  const [openDialog1, setopenDialog1] = useState(false);   //create employee popup 
  const [firstName, setfirstName] = useState(""); //firstName useste create employee
  const [lastName, setlastName] = useState(""); //lastName useste create employee
  const [email, setemail] = useState(""); //email useste create employee
  const [mobileNo, setmobileNo] = useState(""); //mobileNo useste create employee
  const [adharNumber, setadharNumber] = useState(""); //adharNumber useste create employee
  const [countrycode, setcountrycode] = useState("+91");//countrycode useste create employee
  const [employeeId, setemployeeId] = useState("");//employeeId useste create employee4
  const changestatus = "Staff Change Status"; // Define the tooltip text
  const EmployeeCheckinDetails = "View Staff Check-In Details"; // Define the tooltip text
  const updatestaff = "update Staff"; // Define the tooltip text
  const searchtooltip = "Search FirstName LastName Email Mobile Number and Adhar Number"; // Define the tooltip text
  const [editingemployee, seteditingemployee] = useState(null);  //editingemployee

  
  //filter function open menu
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //filter function close menu
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // filter function change
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  // Utility function to add leading zeros
  const formatNumber = (num, size) => {
    let s = String(num);
    while (s.length < size) s = "0" + s;
    return s;
  };

  //fetch getAllEmployees
  const getAllEmployees = async () => {
    try {
      const response = await instance.post(`Manager/getEmployees?page=${page}`, { input });
      setEmployees(response.data.employees);
      setpagination(response.data.pagination);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, [page, input]);

  //handle sorting
  const handleSort = (field) => {
    const isAsc = sortBy === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(field);

    const sortedData = [...employees].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });

    setEmployees(sortedData);
  };
  const startIndex = (page - 1) * rowsPerPage;  // Calculate the start and end index for pagination
  //handle change
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Adjust to be 1-based
  };


  // upadate employee status function open popup
  const handleopenDialog = (employee) => {
    setOpenDialog(true);
    setSelectedEmployee(employee);
  };
  // upadate employee status function close popup
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
  };

  // Call your update API or function here
  const updateEmployeeStatus = async () => {
    try {
      const response = await instance.post("Manager/updateEmployeeStatus", {
        id: selectedEmployee._id,
        status: selectedEmployee.status === true ? false : true,
      });

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseDialog()
        getAllEmployees();

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error('Error updating employee status:', error.message);
    }
  }

  // validations function
  const validateForm = () => {
    let isValid = true;

    // firstName validation
    if (firstName.trim() === '') {
      setfirstNameError("First name is required");
      isValid = false;
    } else {
      setfirstNameError("")
    }
    // countrycode validation
    if (countrycode.trim() === '') {
      setcountrycodeError("countrycode is required");
      isValid = false;
    } else {
      setcountrycodeError("")
    }
    // Validate lastName
    if (lastName.trim() === '') {
      setlastNameError("Last name is required");
      isValid = false;
    } else {
      setlastNameError("");
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.toString().trim())) {
      setemailError("Please enter a valid email address.");
      isValid = false
    } else {
      setemailError("")
    }

    // mobileNo validation
    const mobileRegex = /^[6-9]\d{9}$/; // Regex to match only numbers
    if (!mobileRegex.test(mobileNo.toString().trim())) {
      setmobileNoError('Mobile number is required and must be exactly 10 digits long, starting with a digit between 6 and 9.');
      isValid = false;
    } else {
      setmobileNoError("")
    }

    // adharNumber validation
    const adharRegex = /^\d{12}$/; // Regex to match only numbers
    if (!adharRegex.test(adharNumber.toString().trim())) {
      setadharNumberError('Aadhaar number is required and must be exactly 12 digits long.');
      isValid = false;
    } else {
      setadharNumberError("")
    }

    return isValid;

  };

  // upadate employee status function popupopen
  const handleopenDialog1 = () => {
    setopenDialog1(true);
  };
  // upadate employee status function popup cloe
  const handleCloseDialog1 = () => {
    setopenDialog1(false);
    //clear fields
    setfirstName("")
    setlastName("")
    setemail("")
    setmobileNo("")
    setadharNumber("")
    setcountrycode("")
    // setemployeeId("")
    seteditingemployee(null)
    //clear validationError
    setfirstNameError("")
    setlastNameError("");
    setemailError("");
    setmobileNoError("");
    setadharNumberError("");
    setcountrycodeError("")
  };


  // Call your create API or function here
  const addEmployee = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await instance.post("Manager/Add_Employee", {
        firstName,
        lastName,
        email,
        mobileNo,
        adharNumber,
        countrycode
      });
      console.log(response);
      
      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseDialog1()
        getAllEmployees();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error.response);
    }
  }

 //edit employee function api
  const handleEditClick = (employee) => {
    seteditingemployee(employee);
    // Pre-fill the input fields with the existing data
    setfirstName(employee.firstName || "");
    setlastName(employee.lastName || "");
    setemail(employee.email || "");
    setmobileNo(employee.mobileNo || "");
    setadharNumber(employee.adharNumber || "");
    setcountrycode(employee.countrycode || "");
    setemployeeId(employee?._id)
    //modal open
    setopenDialog1(true);
  };

  const updateEmployee = async () => {
    if (!firstName || !lastName || !email || !mobileNo || !adharNumber || !countrycode) {
      toast.error("Please fill in all required fields.");
      return;
    }
    try {
      const response = await instance.post(`Manager/update_Employee`, {
        employeeId,
        firstName,
        lastName,
        email,
        mobileNo,
        adharNumber,
        countrycode
      })

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleCloseDialog1()
        getAllEmployees();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error.response);
      toast.error(error.response)
    }
  }

  return (
    <React.Fragment>
      <AdminTopbar />
      <div className='add_managers'>
        <React.Fragment>
          <BootstrapDialog
            onClose={handleopenDialog1}
            aria-labelledby="customized-dialog-title"
            open={openDialog1}
            className="add_departments add_managers"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {editingemployee ? "Update Staff" : "Add Staff"}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog1}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Firstname"
                    variant="outlined"
                    type="text"
                    value={firstName}
                    onChange={(e) => { setfirstName(e.target.value); setfirstNameError('') }}
                    error={Boolean(firstNameError)}
                    required
                  />
                  <FormHelperText error={Boolean(firstNameError)}>
                    {firstNameError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="lastname"
                    variant="outlined"
                    type="text"
                    value={lastName}
                    onChange={(e) => { setlastName(e.target.value); setlastNameError('') }}
                    error={Boolean(lastNameError)}
                    required
                  />
                  <FormHelperText error={Boolean(lastNameError)}>
                    {lastNameError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type="Email"
                    value={email}
                    onChange={(e) => { setemail(e.target.value); setemailError('') }}
                    error={Boolean(emailError)}
                    required
                  />
                  <FormHelperText error={Boolean(emailError)}>
                    {emailError}
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className='country-code'>
                    <div>
                      <PhoneInput
                        country={'in'} // Use 'in' instead of 'IND'
                        value={countrycode}
                        onChange={(value) => setcountrycode(value.startsWith('+') ? value : `+${value}`)}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true,
                        }}
                        enableSearch={true} // Enable search functionality
                        isValid={(value) => {
                          return true;
                        }}
                        error={Boolean(countrycodeError)}
                      />
                      <FormHelperText error={Boolean(countrycodeError)}>
                        {countrycodeError}
                      </FormHelperText>
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextField
                        id="outlined-basic"
                        label="mobileNo"
                        variant="outlined"
                        type="number"
                        value={mobileNo}
                        onChange={(e) => { setmobileNo(e.target.value); setmobileNoError('') }}
                        error={Boolean(mobileNoError)}
                        onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 10)) }}
                        required
                        fullWidth
                      />
                      <FormHelperText error={Boolean(mobileNoError)}>
                        {mobileNoError}
                      </FormHelperText>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="outlined-basic"
                    label="AdharNumber"
                    variant="outlined"
                    type="number"
                    value={adharNumber}
                    onChange={(e) => { setadharNumber(e.target.value); setadharNumberError('') }}
                    error={Boolean(adharNumberError)}
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value).toString().slice(0, 12)) }}
                    required
                  />
                  <FormHelperText error={Boolean(adharNumberError)}>
                    {adharNumberError}
                  </FormHelperText>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="footer_button">
              <Button
                className="cancel_button"
                onClick={handleCloseDialog1}
              >
                Cancel
              </Button>
              <Button
                className="add_button"
                onClick={editingemployee ? updateEmployee : addEmployee}
              >
                {editingemployee ? "Update" : "Save"}
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
      </div>
      {/* Status Change Confirmation Dialog */}
      <React.Fragment>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          open={openDialog}
          className="add_departments"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
          Change Staff Status
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className='employee-status'>
              <Typography>
                Are you sure you want to change the status of Staff <strong>{selectedEmployee?.firstName} {selectedEmployee?.lastName}</strong> to <strong>{selectedEmployee?.status ? "Inactive" : "Active"}</strong>?
              </Typography>
            </div>
          </DialogContent>
          <DialogActions className="footer_button">
            <Button className="cancel_button" onClick={handleCloseDialog}>Cancel</Button>
            <Button className="add_button" onClick={() => updateEmployeeStatus()}>Yes</Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className='employees'>
          <div className='employees-text'>
            <Typography variant='h5'>Staff</Typography>
            <Button onClick={handleopenDialog1}>Add Staff</Button>
          </div>
          {/* <div className='tickect-filter'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <TextField 
              label="search"
              placeholder='search'
              fullWidth
              value={input}
              onChange={(e) => setinput(e.target.value)}
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Grid>
          </Grid>
        </div> */}
          <div className='employees-main'>
            <div className='employees-content'>
              <div className='search-options'>
                {/* <Tooltip title={searchtooltip}> */}
                  <TextField
                  placeholder='Search'
                  type='search'
                  variant="outlined"
                  value={input}
                  onChange={(e) => setinput(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                {/* </Tooltip> */}
              </div>
              <div className='pagination-options'>
                <div className='pagination-animation'></div>
                <TablePagination
                  component="div"
                  count={pagination?.totalEmployees}
                  page={page - 1} // Adjust to be 0-based for MUI TablePagination
                  onPageChange={handleChangePage}
                  rowsPerPage={pagination?.limit}
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <React.Fragment>
                      {formatNumber(from, 1)} to <span>{formatNumber(to, 2)}</span>
                      <span>of{' '} {count !== -1 ? count : `more than ${to}`}</span>
                    </React.Fragment>
                  )}
                />
                {/* <div className='filter'>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick1}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className='filter-options'
                >
                  <div className='filter-menu'>
                    <Typography variant='h6'>Filter Departments</Typography>
                    <CloseIcon onClick={handleClose1} />
                  </div>
                  <div className='filter-status'>
                    <React.Fragment>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel value="Active" control={<Radio />} label="Active" className='active' />
                          <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" className='inactive' />
                        </RadioGroup>
                      </FormControl>
                    </React.Fragment>
                    <div className="filter_dropdown">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Departments</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Departments"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Ten</MenuItem>
                          <MenuItem value={20}>Twenty</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="filter_date_picker">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            label="Start"
                            defaultValue={dayjs("2022-04-17")}
                          />
                          <DatePicker
                            label="End"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className='apply_fliters'>
                    <Button>Apply Fliter</Button>
                  </div>
                </Menu>
              </div> */}
              </div>
            </div>
            <div className='employee-table'>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div className='employee-index'>
                          S.NO
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('firstName' && 'lastName')}>
                          Full Name
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('email')}>
                          Email
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('mobileNo')}>
                          Mobile Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('adharNumber')}>
                          Adhar Number
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' onClick={() => handleSort('status')}>
                          Status
                          <NorthIcon />
                          <SouthIcon />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='employee-name' >
                          Action
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employees.length > 0 ? (
                      employees.map((employee, index) => (
                        <TableRow tabIndex={-1} key={employee._id}>
                          <TableCell component="th" scope="row">
                            {startIndex + index + 1}
                          </TableCell>
                          <TableCell component="th" scope="row">{employee?.firstName} {employee?.lastName}</TableCell>
                          <TableCell align="left">{employee?.email}</TableCell>
                          <TableCell align="left">{employee?.countrycode} {employee?.mobileNo}</TableCell>
                          <TableCell align="left">{employee?.adharNumber}</TableCell>
                          <TableCell align='left'>
                          <Tooltip title={changestatus}><span onClick={() => { handleopenDialog(employee) }} style={{ color: employee.status === true ? "#00A651" : " #EB5757", background: employee.status === true ? " #E5FCF0" : "#FFE8E8", cursor: "pointer" }}>{employee.status === true ? "Active" : "Inactive"}</span></Tooltip>
                          </TableCell>
                          <TableCell align="left" className='tickects-lasttablecell'>
                            <div className='view-btn' onClick={() => navigate(`/admin/employee-details/${employee._id}`)}><Tooltip title={EmployeeCheckinDetails} ><VisibilityIcon /></Tooltip></div>
                            <div className='edit-btn' onClick={() => handleEditClick(employee)}><Tooltip title={updatestaff}><img src={edit} alt="" /></Tooltip></div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No Data Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
      )
      }
    </React.Fragment>
  )
}

export default Employees;
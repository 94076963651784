import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import AdminTopbar from '../../admin/layouts/Topbar';
import '../../admin/styles/pages/dashboard.css';
import Chart from 'react-apexcharts'
import { Grid, Typography } from '@mui/material';
import total_departments from '../../superadmin/assets/icons/deparment-icon-1.svg';
import pending_tickets from '../../superadmin/assets/icons/tickets-1.svg';
import progress_tickets from '../../superadmin/assets/icons/inprogress-tickets.svg';
import completed_tickets from '../../superadmin/assets/icons/completed-tickets.svg';
import rejected_tickets from '../../superadmin/assets/icons/rejected-tickets.svg';
import instance from "../../utils/api";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';


const Dashboard = () => {
  const [loading, setLoading] = useState(true); // useState to handle loading
  const [series, setSeries] = useState([0, 0, 0, 0]);   // usestate for piechart
  const [chartOptions, setChartOptions] = useState(null); // usestate options
  const [selectedYear, setSelectedYear] = useState(dayjs().year()); // usestate for year
  const [employees, setEmployees] = useState([]);   //usesate for employee


  //fetch fetchFieldOfficer
  const getAllEmployees = async () => {
    try {
      const response = await instance.post('Manager/getEmployees');
      setEmployees(response.data.employees);
    } catch (error) {
      console.error('Error fetching users:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);
  // api call for piechart
  const fetchPieChartData = async () => {
    try {
      const response = await instance.get('Manager/getPieChartDataByDept');
      const data = response.data.result;
      setSeries([
        data.Created || 0,
        data.Inprogress || 0,
        data.Completed || 0,
        data.Rejected || 0
      ]);
    } catch (error) {
      console.error('Error fetching pie chart data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPieChartData();
  }, []);

  const options = {
    labels: ["Created", "Inprogress", "Completed", "Rejected"],
    colors: ["#D2AB67", "#F2994A", "#00A651", "#EB5757"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "50px",
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontFamily: 'Inter sans-serif ',
              fontWeight: 600,
              color: "#383838",
              fontSize: "16px",
            }
          }
        }
      }
    }
  };


  //line chart api call
  const fetchChartData = async (year) => {
    try {
      const response = await instance.post('Manager/getBarchatDataByDept', {
        year: year.toString(),
      });

      const data = response.data.result;
      setChartOptions({
        series: [
          {
            name: "Created",
            data: data.Created,
            color: "#D2AB67",
          },
          {
            name: "In Progress",
            data: data.Inprogress,
            color: "#F2994A",
          },
          {
            name: "Completed",
            data: data.Completed,
            color: "#00A651",
          },
          {
            name: "Rejected",
            data: data.Rejected,
            color: "#EB5757",
          }
        ],
        options: {
          chart: {
            height: 500,
            type: 'line',
            zoom: {
              enabled: false
            },

            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
            width: 2
          },
          yaxis: {
            title: {
              text: 'Ticket Count',
            }
          },
          grid: {
            row: {
              colors: ['#FFFFFF', 'transparent'],
              opacity: 0.5,
            }
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          },
          legend: {
            position: 'right',
            offsetY: 7,
            height: 230
          }

        }
      });
    } catch (error) {
      console.error('Error fetching chart data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(selectedYear);
  }, [selectedYear]);


  return (
    <React.Fragment>
      <AdminTopbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <section className='dashboard-section'>
          <Typography variant='h5'>Overview Section</Typography>
          <div>
            <Grid  container spacing={4}>
              <Grid className='dashboard_box' item xs={12} sm={6} md={2.4} lg={2.4}>
                <div className='dashboard-box-1'>
                  <img src={total_departments} alt='total_departments' />
                  <Typography>Total Staff</Typography>
                  <Typography variant='h6'>{employees.length}</Typography>
                </div>
              </Grid>
              <Grid className='dashboard_box' item xs={12} sm={6} md={2.4} lg={2.4}>
                <div className='dashboard-box-2'>
                  <img src={pending_tickets} alt='pending_tickets' />
                  <Typography>Created Tickets</Typography>
                  <Typography variant='h6'>{series[0]}</Typography>
                </div>
              </Grid>
              <Grid className='dashboard_box' item xs={12} sm={6} md={2.4} lg={2.4}>
                <div className='dashboard-box-3'>
                  <img src={progress_tickets} alt='progress_tickets' />
                  <Typography>Inprogress Tickets</Typography>
                  <Typography variant='h6'>{series[1]}</Typography>
                </div>
              </Grid>
              <Grid className='dashboard_box' item xs={12} sm={6} md={2.4} lg={2.4}>
                <div className='dashboard-box-4'>
                  <img src={completed_tickets} alt='completed_tickets' />
                  <Typography>Completed Tickets</Typography>
                  <Typography variant='h6'>{series[2]}</Typography>
                </div>
              </Grid>
              <Grid className='dashboard_box' item xs={12} sm={6} md={2.4} lg={2.4}>
                <div className='dashboard-box-5'>
                  <img src={rejected_tickets} alt='rejected_tickets' />
                  <Typography>Rejected Tickets</Typography>
                  <Typography variant='h6'>{series[3]}</Typography>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='charts-section'>
            <div className='chart-year'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={['year']}
                  label="Select Year"
                  value={dayjs().year(selectedYear)}
                  onChange={(newValue) => setSelectedYear(newValue.year())}
                />
              </LocalizationProvider>
            </div>
            <Grid className='side_right_pie_chart' container spacing={2}>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <div className='piechart'>
                  <Typography variant='h5'>Ticket Status Overview</Typography>
                  <Chart
                    options={options}
                    series={series}
                    type="donut"
                    width="350px"
                    height={300}
                  />
                </div>
              </Grid>
              <Grid className='side_right_pie_chart_2' item xs={12} sm={6} md={7} lg={7}>
                {chartOptions ? (<div className='wavechart'>
                  <Typography variant='h5'>Tickets Trend</Typography>
                  <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={250} />
                </div>) : null}
                {/* {chartOptions && (<div className='wavechart'>
                <Typography variant='h5'>Tickets Trend</Typography>
                <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={250} />
              </div>)} */}
              </Grid>
            </Grid>
          </div>
        </section>
      )}
    </React.Fragment>

  )
}

export default Dashboard;

import React, { useState } from "react";
import "../../superadmin/styles/pages/auth.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Grid, TextField, IconButton, InputAdornment, Typography, FormHelperText } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../superadmin/assets/logo.svg";
import login_logo from "../../superadmin/assets/login-logo.svg";
// import logo_image from "../../superadmin/assets/login-image.svg";
import logo_image from "../../superadmin/assets/loginlogo.jpg";
import instance, { updateToken } from "../../utils/api";

const Login = ({ setTref }) => {
  const navigate = useNavigate();
  //password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  //loginsuperadmin usestates define
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  //validation for loginsuperadmin
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const validateForm = () => {
    let isValid = true;

    //email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(email.toString().trim())) {
      setEmailError("Invalid email format");
      isValid = false
    } else {
      setEmailError("")
    }

    // password validation
    if (!password) {
      setPasswordError("password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }
    return isValid;
  };



  //login functionality for superadmin
  const loginsuperadmin = async () => {

    if (!validateForm()) {
      return;
    }
    try {
      const response = await instance.post("Commissioner/SignIn", {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.data.success === true) {
        toast.success(response.data.message);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('status', response.data.status);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('name', response.data.name);
        updateToken(response.data.token)
        setTref(prev => prev + 1)
        navigate("/superadmin/dashboard")

      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Login failed:', error.response);
      toast.error(error.response.data.message);
    }
  }

  //click on enter button login to dashboard function
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      loginsuperadmin();
    }
  };

  return (
    <React.Fragment>
      <section className="login-section">
        <div className="login-image">
          <div className="login_logo">
            <img src={login_logo} alt="login_logo" />
          </div>
          <div className="login_logo_1">
            <img src={logo_image} alt="logo_image" />
          </div>
        </div>
        <div className="login-main">
          <div className="login-content">
            <div>
              <Grid container direction="column" spacing={2}>
                <div className="login-logo">
                  {/* <img className="login-img" src={logo} alt="logo" /> */}
                  <h1 className='Login_header'>Zilla Parishad</h1>
                </div>
                <Typography variant="h4">Welcome</Typography>
                {/* <Typography>Welcome message</Typography> */}
                <Grid item>
                  <TextField
                    type="email"
                    label="Email"
                    placeholder="Enter Your Email"
                    variant="outlined"
                    required
                    fullWidth
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                    error={Boolean(emailError)}
                  />
                  <FormHelperText error={Boolean(emailError)}>
                    {emailError}
                  </FormHelperText>
                </Grid>
                <Grid item>
                  <TextField
                    label="password"
                    variant="outlined"
                    placeholder="Enter Your Password"
                    required
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value); setPasswordError('') }}
                    onKeyDown={handleKeyDown} // Attach handleKeyDown here
                    error={Boolean(passwordError)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText error={Boolean(passwordError)}>
                    {passwordError}
                  </FormHelperText>
                </Grid>
                <Grid item>
                  <Link to="/superadmin/forgotpassword"><Button disableElevation disableRipple className="forgot-text">
                    Forgot Password
                  </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <div className="login-logo">
                    <Button onClick={loginsuperadmin}>Login</Button>
                  </div>
                </Grid>
                <Grid item>
                  <div className="btn-login">
                    <Button onClick={() => { navigate("/admin") }}>Login as Admin</Button>
                  </div>
                </Grid>
                {/* <Link to="/admin" className="login-link">Login as Admin</Link> */}
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;

import React, { useState } from "react";
import "../../superadmin/styles/pages/auth.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Button,
    Grid,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
    FormHelperText,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../superadmin/assets/logo.svg";
import login_logo from "../../superadmin/assets/login-logo.svg";
import logo_image from "../../superadmin/assets/loginlogo.jpg";
import instance from "../../utils/api";
import OtpInput from 'react-otp-input';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Forgotpassword = () => {
    const navigate = useNavigate();
    const [email, setemail] = useState("");
    const [otp, setotp] = useState("");
    const [sendotp, setsendotp] = useState(false);
    const [Verifyotp, setVerifyotp] = useState(false);
    const [newPassword, setnewPassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")

    //SendOTP functionality for superadmin
    const SendOTP = async () => {

        try {
            const response = await instance.post("Commissioner/SendOTP", {
                email,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success(response.data.message);
                setsendotp(true)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error.response);
        }
    }

    //VerifyOTP functionality for superadmin
    const VerifyOTP = async () => {

        try {
            const response = await instance.post("Commissioner/ConfirmOTP", {
                email,
                otp
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success(response.data.message);
                setVerifyotp(true)
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error('VerifyOTP failed:', error.response.data);
        }
    }

    //Resetpassword functionality for superadmin
    const Resetpassword = async () => {

        try {
            const response = await instance.post("Commissioner/ResetPassword", {
                email,
                otp,
                newPassword,
                confirmPassword
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success(response.data.message);
                setVerifyotp(true);
                navigate("/superadmin")

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.error(error.response);
        }
    }



    return (
        <React.Fragment>
            <section className="login-section">
                <div className="login-image">
                    <div className="login_logo">
                        <img src={login_logo} alt="login_logo" />
                    </div>
                    <div className="login_logo_1">
                        <img src={logo_image} alt="logo_iamge" />
                    </div>
                </div>
                <div className="login-main">
                    <div className="login-content">

                        <div className="main_otp">
                            <Grid container direction="column" spacing={3}>
                                <div className="login-logo">
                                    {/* <img className="login-img" src={logo} alt="logo" /> */}
                                    <h1 className='Login_header'>Zilla Parishad</h1>
                                </div>
                                <Typography variant="h4">Kindly provide a valid Email address</Typography>
                                <Grid item>
                                    <TextField
                                        type="email"
                                        label="Email"
                                        placeholder="Enter Your Email"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        value={email}
                                        onChange={(e) => setemail(e.target.value)}
                                        disabled={sendotp}
                                    />
                                </Grid>
                                {sendotp ?
                                    <React.Fragment>
                                        <Grid item>
                                            <div className="send-otp-number">
                                                <OtpInput
                                                    className='input'
                                                    value={otp}
                                                    onChange={setotp}
                                                    numInputs={4}
                                                    renderSeparator={<span>-</span>}
                                                    renderInput={(props) => <input {...props} disabled={Verifyotp} />}

                                                />
                                            </div>
                                        </Grid>
                                        {Verifyotp ? null :
                                            <Link className='login-link' onClick={SendOTP}>Resend OTP</Link>}
                                    </React.Fragment>

                                    : null}
                                {Verifyotp ?
                                    <React.Fragment>
                                        <Grid item>
                                            <TextField
                                                type="password"
                                                label="New password"
                                                placeholder="Enter Your New Password"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={newPassword}
                                                onChange={(e) => setnewPassword(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                type="password"
                                                label="Confirm Password"
                                                placeholder="Enter Your Confirm Password"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                value={confirmPassword}
                                                onChange={(e) => setconfirmPassword(e.target.value)}
                                            />
                                        </Grid>

                                    </React.Fragment> : null}

                                {Verifyotp ? (
                                    <Grid item>
                                        <div className="login-logo login-logo_2">
                                            <Button onClick={() => { navigate('/superadmin') }}><ArrowBackIcon />Back</Button>
                                            <Button onClick={Resetpassword}>Submit</Button>
                                        </div>
                                    </Grid>
                                ) : (
                                    sendotp ? (
                                        <Grid item>
                                            <div className="login-logo login-logo_2">
                                                <Button onClick={() => { navigate('/superadmin') }}><ArrowBackIcon />Back</Button>
                                                <Button onClick={VerifyOTP}>Verify OTP</Button>
                                            </div>
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <div className="login-logo login-logo_2">
                                                <Button onClick={() => { navigate('/superadmin') }}><ArrowBackIcon />Back</Button>
                                                <Button onClick={SendOTP}>Send OTP</Button>
                                            </div>
                                        </Grid>
                                    )
                                )}

                            </Grid>
                        </div>
                    </div >
                </div >
            </section >
        </React.Fragment>
    )
}

export default Forgotpassword;

import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, InputLabel, FormControl, Select, MenuItem, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import instance from "../../utils/api";
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


function Add_notification({ setsendstaffnotification }) {
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);  //usesate for employee
  const [personName, setPersonName] = useState([]);
  const [title, settitle] = useState("");  //usestate for sendNotification
  const [discription, setdiscription] = useState("");
  console.log(personName);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //clear fields
    settitle("");
    setdiscription("");
    setPersonName([""])

  };

  //get employee api call
  const getAllEmployees = async () => {
    try {
      const response = await instance.post("Manager/getEmployees");
      setEmployees(response.data.employees);
    } catch (error) {
      console.error('Error fetching employees:', error.message);
    }
  };

  useEffect(() => {
    getAllEmployees();
  }, []);
  
  //multi select
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setPersonName(employees.map(emp => emp._id));
    } else {
      setPersonName([]);
    }
  };

  const isAllSelected = employees.length > 0 && personName.length === employees.length;


  //sendNotification
  const sendstaffNotification = async () => {
    try {
      // Validation
      if (!title || !discription || !personName) {
        toast.error('Please enter title, Employee ID, and discription.');
        return;
      }
      const response = await instance.post("Manager/sendStaffNotification",
        {
          title,
          discription,
          empId: personName
        });

      if (response.data.success === true) {
        toast.success(response.data.message);
        handleClose()
        setsendstaffnotification(true)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.message);
      console.error(error.response);
    }
  }


  return (
    <div>
      <React.Fragment>
        <Button
          className="title_add_button"
          variant="outlined"
          onClick={handleClickOpen}
        >
          Send Whatsapp
        </Button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="add_departments"
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Send Staff Notifications
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Grid container spacing={2} columnSpacing={2} rowSpacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="departments_forms">
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    value={discription}
                    onChange={(e) => setdiscription(e.target.value)}
                    required
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="filter_dropdown">
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-checkbox-label">Employee</InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={(e) => setPersonName(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                      input={<OutlinedInput label="Employee" />}
                      renderValue={(selected) => selected.map((id) => {
                        const employee = employees.find(emp => emp._id === id);
                        return employee ? `${employee.firstName} ${employee.lastName}` : null;
                      })
                        .join(', ')}

                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                      }}
                    >
                      <MenuItem>
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={!isAllSelected && personName.length > 0}
                          onChange={handleSelectAll}
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {employees.map((employee) => (
                        <MenuItem key={employee._id} value={employee._id}>
                          <Checkbox checked={personName.indexOf(employee._id) > -1} />
                          <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="footer_button">
            <Button
              className="cancel_button"
              autoFocus
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="add_button"
              autoFocus
              onClick={sendstaffNotification}
            >
              Send
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  )
}

export default Add_notification;
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from "@mui/material/styles";
import { Dialog } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 4,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const assignPopup = ({ open, setOpen, data, empid, setempid,onclick,buttondisable }) => {
    console.log(open);
    // console.log(empid);


    return (
        <>
            <React.Fragment>
                <BootstrapDialog
                    onClose={() => { setOpen(false) }}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    className="add_departments"
                >
                    <DialogTitle id="customized-dialog-title" onClose={() => { setOpen(false) }}>
                        Assign Employee
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => { setOpen(false) }}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <div className='employee-status'>
                            <Stack direction="row" spacing={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Employees</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={empid}
                                        label="Employees"
                                        onChange={(e) => setempid(e.target.value)}
                                    >
                                        {data.map((empdata) => (
                                            <MenuItem value={empdata._id}>{empdata.firstName} {empdata.lastName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </div>
                    </DialogContent>
                    <DialogActions className="footer_button">
                        <Button className="cancel_button" onClick={() => { setOpen(false) }}>Cancel</Button>
                        <Button className="add_button" onClick={onclick} disabled={buttondisable} >Assign</Button>
                    </DialogActions>
                </BootstrapDialog>
            </React.Fragment>
        </>
    )
}

export default assignPopup